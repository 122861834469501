<template>
  <div class="page">
    <!-- {{ this.$route.query }}
    {{ origin }} -->
    <div class="top_box">
      <img src="./img/index/topPic.png" alt="" />
    </div>
    <!-- 工具 -->
    <div class="tool_box">
      <div class="item" @click="toPath('clapCasuallyCreate')">
        <img src="./img/index/camera.png" alt="" />
        <div class="name">随手拍</div>
      </div>
      <div class="item" @click="toPath('myReport')">
        <img src="./img/index/report.png" alt="" />
        <div class="name">我的上报</div>
      </div>
    </div>
    <!-- 说明 -->
    <div class="message_box">
      特此说明：随手拍功能主要是收集居民在日常生活中发生的各类问题，事件等；并增加老百姓建议/协商/矛盾调解的渠道，有对应人员进行答复。
    </div>
    <!-- 列表 -->
    <div class="list">
      <div class="box">
        <!-- <v-list
          :finished="finished"
          @onLoad="onLoad"
          :finishedText="finishedText"
          ref="list"
        > -->
        <div
          class="item"
          @click="toDetail(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="img_box">
            <img :src="imgStrSplit(item.eventPicture)" alt="" />
          </div>
          <div class="detail_box">
            <div class="name">{{ item.eventDescription }}</div>
            <div class="type">所属分类：{{ item.incidentName }}</div>
            <div class="time">{{ item.createTime }}</div>
          </div>
          <div class="status_box">
            <p v-if="item.status == 4">已完成<span class="green"></span></p>
            <p v-else-if="item.status == 2">已接单<span class="blue"></span></p>
            <p v-else-if="item.status == 1">待受理<span class="red"></span></p>
            <p v-else>处理中<span class="yellow"></span></p>
          </div>
        </div>
        <!-- </v-list> -->
      </div>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { snapshotListUrl } from "./api.js";
export default {
  name: "clapCasually",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 4,
      },
      origin: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.origin = window.location.origin;
    if (this.$route.query.latitude) {
      localStorage.setItem("latitude", this.$route.query.latitude);
    }
    if (this.$route.query.longitude) {
      localStorage.setItem("longitude", this.$route.query.longitude);
    }
  },
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "随手拍")) {
      this.getList();
    }
    gloabalCount("", 69, 1);
  },
  methods: {
    toPath(name) {
      this.$router.push({
        name: name,
      });
    },
    async onLoad() {
      if (await toRegister(this.$route.path, this.$route.query, "随手拍")) {
        this.getList();
      }
    },
    getList() {
      //获取随手拍列表
      let params = {
        pageNum: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        tenantId: this.tenantId,
        workTypeName: "随手拍",
      };
      this.$axios
        .get(`${snapshotListUrl}`, {
          // .get(
          // `https://test.zhuneng.cn/gateway/hc-serve/miniapi/snapshot/snapshotList`,
          // {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.listData = res.data.records;
            //   if (res.data.pages <= res.data.current) {
            //     this.finished = true;
            //   } else {
            //     this.$refs.list._data.loading = false;
            //   }
            //   this.finishedText = "没有更多了";
            //   if (res.data.records.length === 0) {
            //     this.finishedText = "";
            //     return;
            //   }
            //   res.data.records.forEach((item) => {
            //     this.listData.push(item);
            //   });
            // } else {
            // }
          }
        });
    },
    toDetail(data) {
      this.$router.push({
        name: "clapCasuallyDetail",
        query: {
          id: data.id,
        },
      });
    },
    imgStrSplit(str) {
      //多个图片截取第一张
      if (str.indexOf(",") != -1) {
        return str.split(",")[0];
      } else {
        return str;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  padding-bottom: 30px;

  .list {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;

    // min-height: 100vh;
    .box {
      width: 100%;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding: 0 32px 0 38px;

      .item {
        box-sizing: border-box;
        width: 100%;
        height: 212px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        display: flex;
        padding: 36px 0 32px;

        .status_box {
          flex: 1;
          height: 100%;

          p {
            font-size: 26px;
            color: rgba(0, 0, 0, 0.5);
            text-align: right;

            span {
              width: 10px;
              height: 10px;

              border-radius: 50%;
              display: inline-block;
              position: relative;
              top: -10px;
              right: -2px;
            }

            .green {
              background: #66b95a;
            }

            .blue {
              background: #8db0e8;
            }

            .red {
              background: #c33333;
            }

            .yellow {
              background: #f9980b;
            }
          }
        }

        .detail_box {
          box-sizing: border-box;
          width: 330px;
          height: 100%;
          padding-left: 14px;

          .name {
            margin-top: 4px;
            font-size: 34px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .type {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
          }

          .time {
            margin-top: 32px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.41);
          }
        }

        .img_box {
          width: 144px;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 144px;
            height: 144px;
          }
        }
      }

      .item:nth-last-child(1) {
        border: none;
      }
    }
  }

  .message_box {
    margin-top: 22px;
    width: 100%;
    height: 162px;
    background: #ffffff;
    font-size: 24px;
    color: #797d7f;
    box-sizing: border-box;
    padding: 32px 50px 0;
    margin-bottom: 22px;
  }

  .tool_box {
    width: 100%;
    height: 204px;
    margin-top: -128px;
    z-index: 2;
    position: relative;
    box-sizing: border-box;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;

    .item {
      height: 100%;
      width: 330px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      display: flex;
      align-items: center;

      .name {
        font-size: 36px;
        font-weight: 600;
        color: #0f0e47;
        margin-left: 20px;
      }

      img {
        width: 120px;
        height: 120px;
        margin-left: 26px;
      }
    }
  }

  .top_box {
    width: 100%;
    height: 600px;
    z-index: -1 !important;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
